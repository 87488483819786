/*eslint-disable*/
import React, { useState } from 'react';
import { FormControl, Grid } from '@mui/material';
import TextField from '../../core-components/atoms/TextField';
import Autocomplete from '../../core-components/atoms/Autocomplete';
import AddressTextField from '../../core-components/molecules/AddressTextField';
import Map from '../revampedOrder/NodeMap';
import Switch from '../../core-components/atoms/Switch';
import MultiSelect from './MultiSelect';
import { customerIcon, organizationIcon, supplierIcon } from './icons';
import { ChevronRight } from '@material-ui/icons';
import FormikTextField from '../../core-components/molecules/FormikTextField';
import FormikAutocomplete from '../../core-components/molecules/FormikAutocomplete';
import { organizationTypes } from './constants';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { Country } from 'country-state-city';

const NodeForm = ({
  formik,
  nodeTypes,
  users,
  customers,
  isEntity,
  organization,
  savedNodes,
  suppliers,
  edit,
  entity,
  search,
  setSearch,
  viewOnly,
  userSearch,
  setUserSearch
}) => {
  const borderClass =
    'border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 pt-3 pb-3 mt-3 number-input-container';

  const isWarehouseNode = formik.values.nodeClass == 'INTERNAL';

  let nestedOptions = [
    {
      label: (
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            {customerIcon()}
            <spam>Customer</spam>
          </div>
          <ChevronRight />
        </div>
      ),
      options: customers?.map((customer) => ({
        label: customer.name,
        value: customer.id,
        address: customer.billingAddress,
        code: customer.code,
        type: customer.type,
        name: customer.name
      })),
      entityType: 'CUSTOMER'
    }
  ];

  if (organization?.type == 'MANUFACTURER') {
    nestedOptions.push({
      label: (
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            {supplierIcon()}
            <spam>Supplier</spam>
          </div>
          <ChevronRight />
        </div>
      ),
      options: suppliers?.map((supplier) => ({
        label: supplier.name,
        value: supplier.id,
        address: supplier.billingAddress,
        code: supplier.code,
        type: supplier.type,
        name: supplier.name
      })),
      entityType: 'SUPPLIER'
    });
  }

  nestedOptions.push({
    label: (
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center">
          {organizationIcon()}
          <spam>Organization</spam>
        </div>
      </div>
    ),
    value: organization?.id,
    address: organization?.address,
    code: '-',
    type: organizationTypes[organization?.type],
    name: organization?.name,
    entityType: 'ORGANIZATION'
  });

  let country;

  const countriesData = Country.getAllCountries();
  if (organization?.country) {
    country = countriesData?.find(
      (country) =>
        country.isoCode == organization?.country ||
        country.name == organization?.country
    );
  }


  return (
    <div className="">
      <div className="mt-10 mb-10">
        {!edit && entity && entity.name && (
          <div className="border border-solid border-[#E1E5F0] px-4 py-8 bd-4">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
              <div>
                <p className="text-xs font-medium text-[#696F80] mb-1">
                  {entity.entityType} NAME
                </p>
                <p className="text-sm">{entity?.name}</p>
              </div>
              <div>
                <p className="text-xs font-medium text-[#696F80] mb-1">
                  {entity.entityType} CODE
                </p>
                <p className="text-sm">{entity?.code || '-'}</p>
              </div>
              <div>
                <p className="text-xs font-medium text-[#696F80] mb-1">
                  {entity.entityType} TYPE
                </p>
                <p className="text-sm">{entity?.type}</p>
              </div>
              <div>
                <p className="text-xs font-medium text-[#696F80] mb-1">
                  ADDRESS
                </p>
                <p className="text-sm">{entity?.address}</p>
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col gap-10">
          <form onSubmit={formik.handleSubmit}>
            <div className="gap-6">
              <div className="w-full h-72 relative">
                <Map
                  setSingleLocationLatlng={(val) =>
                    formik.setFieldValue('latLng', val)
                  }
                  singleLocationLatlng={formik.values.latLng}
                  showSingleSearchField={true}
                  setSingleLocationAddress={(address) =>
                    formik.setFieldValue('address', address)
                  }
                  editable={true}
                  address={formik.values.address || ''}
                />
              </div>
            </div>

            {isEntity && savedNodes.length == 0 && !edit ? (
              <>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 mt-10">
                  <div className="flex items-center">
                    <p className="font-semibold">
                      Entity & Type<span className="text-error ml-2">*</span>
                    </p>
                  </div>
                  <div>
                    <FormControl fullWidth>
                      <MultiSelect
                        options={nestedOptions}
                        value={formik.values.entity || {}}
                        formik={formik}
                        name="entity"
                        setValue={(value) => {
                          formik.setFieldValue('entity', value);
                          formik.setFieldValue(
                            'nodeClass',
                            value.entityType == 'ORGANIZATION'
                              ? 'INTERNAL'
                              : 'EXTERNAL'
                          );
                        }}
                        getOptionLabel={(option) => option.name}
                        placeholder="Select Entity"
                        disabled={viewOnly}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormikAutocomplete
                      name="nodeType"
                      formik={formik}
                      options={nodeTypes}
                      onChange={async (e, value) =>
                        formik.setFieldValue('nodeType', value)
                      }
                      value={formik.values?.nodeType || {}}
                      placeholder="Select Type"
                      getOptionLabel={(option) => option.label || ''}
                      disabled={viewOnly}
                    />
                  </div>
                </div>

                {isWarehouseNode && (
                  <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 number-input-container mt-6">
                    <div className="flex items-center">
                      <p className="font-semibold">
                        Capacity<span className="text-error ml-2">*</span>
                      </p>
                    </div>
                    <div>
                      <FormControl fullWidth>
                        <FormikTextField
                          name="capacity"
                          formik={formik}
                          placeholder="Enter here"
                          InputProps={{
                            endAdornment: (
                              <span className="whitespace-nowrap">Sq. ft.</span>
                            )
                          }}
                          type="number"
                          disabled={viewOnly}
                        />
                      </FormControl>
                    </div>
                    <div>
                      <FormControl fullWidth>
                        <FormikTextField
                          name="pallets"
                          formik={formik}
                          placeholder="Enter here"
                          InputProps={{ endAdornment: 'Pallet' }}
                          type="number"
                          disabled={viewOnly}
                        />
                      </FormControl>
                    </div>
                  </div>
                )}

                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 mt-6">
                  <div className="flex items-center">
                    <p className="font-semibold">
                      Node Name & Code<span className="text-error ml-2">*</span>
                    </p>
                  </div>
                  <div>
                    <FormControl fullWidth>
                      <FormikTextField
                        name="name"
                        formik={formik}
                        placeholder="Enter Name"
                        disabled={viewOnly}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl fullWidth>
                      <TextField
                        value={formik.values.code}
                        onChange={(e) =>
                          formik.setFieldValue('code', e.target.value)
                        }
                        placeholder="Enter Code"
                        disabled={viewOnly}
                      />
                    </FormControl>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 mt-6">
                  <div className="flex items-center">
                    <p className="font-semibold">
                      Node Name & Type<span className="text-error ml-2">*</span>
                    </p>
                  </div>
                  <div>
                    <FormControl fullWidth>
                      <FormikTextField
                        name="name"
                        formik={formik}
                        placeholder="Enter Name"
                        disabled={viewOnly}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormikAutocomplete
                      name="nodeType"
                      formik={formik}
                      options={nodeTypes}
                      onChange={async (e, value) =>
                        formik.setFieldValue('nodeType', value)
                      }
                      value={formik.values?.nodeType || {}}
                      placeholder="Select Type"
                      getOptionLabel={(option) => option.label || ''}
                      disabled={viewOnly}
                    />
                  </div>
                </div>

                {isWarehouseNode && (
                  <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 number-input-container mt-6">
                    <div className="flex items-center">
                      <p className="font-semibold">
                        Capacity<span className="text-error ml-2">*</span>
                      </p>
                    </div>
                    <div>
                      <FormControl fullWidth>
                        <FormikTextField
                          name="capacity"
                          formik={formik}
                          placeholder="Enter here"
                          InputProps={{
                            endAdornment: (
                              <span className="whitespace-nowrap">Sq. ft.</span>
                            )
                          }}
                          type="number"
                          disabled={viewOnly}
                        />
                      </FormControl>
                    </div>
                    <div>
                      <FormControl fullWidth>
                        <FormikTextField
                          name="pallets"
                          formik={formik}
                          placeholder="Enter here"
                          InputProps={{ endAdornment: 'Pallet' }}
                          type="number"
                          disabled={viewOnly}
                        />
                      </FormControl>
                    </div>
                  </div>
                )}

                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 number-input-container mt-6">
                  <div className="flex items-center">
                    <p className="font-semibold">
                      {isWarehouseNode
                        ? 'Location Code & Manager'
                        : 'Location Code'}
                    </p>
                  </div>
                  <div
                    className={isWarehouseNode ? 'col-span-1' : 'col-span-2'}
                  >
                    <FormControl fullWidth>
                      <TextField
                        value={formik.values.code}
                        onChange={(e) =>
                          formik.setFieldValue('code', e.target.value)
                        }
                        placeholder="Enter Code"
                        disabled={viewOnly}
                      />
                    </FormControl>
                  </div>
                  {isWarehouseNode && (
                    <div>
                      <Autocomplete
                        options={users}
                        onChange={async (e, value) =>
                          formik.setFieldValue('manager', value)
                        }
                        value={formik.values?.manager || {}}
                        placeholder="Manager (Optional)"
                        getOptionLabel={(option) =>
                          option.firstName && option.lastName
                            ? option.firstName + ' ' + option.lastName
                            : ''
                        }
                        disabled={viewOnly}
                        inputValue={userSearch}
                        onInputChange={(e, value) => {
                          setUserSearch && setUserSearch(value);
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
              <div className="flex items-center">
                <p className="font-semibold">
                  Address<span className="text-error ml-2">*</span>
                </p>
              </div>
              <div className="col-span-2">
                <AddressTextField
                  onSelect={(e, l) => {
                    formik.setFieldValue('address', e);
                    formik.setFieldValue('latLng', l);
                  }}
                  value={formik.values.address}
                  isFormik={true}
                  formik={formik}
                  name="address"
                  search={search}
                  setSearch={setSearch}
                  disabled={viewOnly}
                />
              </div>
            </div>

            {isEntity && savedNodes.length == 0 && isWarehouseNode && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 number-input-container mt-6">
                <div className="flex items-center">
                  <p className="font-semibold">Manager</p>
                </div>
                <div className="col-span-2">
                  <Autocomplete
                    options={users}
                    onChange={async (e, value) =>
                      formik.setFieldValue('manager', value)
                    }
                    value={formik.values?.manager || {}}
                    placeholder="Manager (Optional)"
                    getOptionLabel={(option) =>
                      option.firstName && option.lastName
                        ? option.firstName + ' ' + option.lastName
                        : ''
                    }
                    disabled={viewOnly}
                    inputValue={userSearch}
                    onInputChange={(e, value) => {
                      setUserSearch && setUserSearch(value);
                    }}
                  />
                </div>
              </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
              <div className="flex items-center">
                <p className="font-semibold">POC Name & Phone Number</p>
              </div>
              <div>
                <FormControl fullWidth>
                  <TextField
                    value={formik.values.pocName}
                    onChange={(e) =>
                      formik.setFieldValue('pocName', e.target.value)
                    }
                    placeholder="Enter Name"
                    disabled={viewOnly}
                  />
                </FormControl>
              </div>
              <div>
                <FormControl fullWidth>
                  <PhoneInput
                    country={country?.isoCode?.toLowerCase() || 'pk'}
                    value={formik?.values?.pocContact || ''}
                    onChange={(phone) =>
                      formik.setFieldValue('pocContact', phone)
                    }
                    variant="outlined"
                    inputStyle={{ width: '100%', height: '40px' }}
                    disabled={viewOnly}
                  />
                </FormControl>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
              <div className="flex items-center">
                <p className="font-semibold">Additional Detail</p>
              </div>
              <div className="col-span-2">
                <FormControl fullWidth>
                  <TextField
                    value={formik.values.description}
                    onChange={(e) =>
                      formik.setFieldValue('description', e.target.value)
                    }
                    placeholder="Type here"
                    disabled={viewOnly}
                  />
                </FormControl>
              </div>
            </div>

            {(entity?.label == 'Organization' ||
              entity?.entityType == 'ORGANIZATION' ||
              formik.values.nodeClass == 'INTERNAL') && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                <div className="flex items-center">
                  <p className="font-semibold">
                    Required Features<span className="text-error ml-2">*</span>
                  </p>
                </div>
                <div>
                  <Switch
                    checked={formik.values.IMS}
                    onChange={() =>
                      formik.setFieldValue('IMS', !formik.values.IMS)
                    }
                    label="IMS"
                    disabled={true}
                  />
                </div>
                <div>
                  <Switch
                    checked={formik.values.WMS}
                    onChange={() =>
                      formik.setFieldValue('WMS', !formik.values.WMS)
                    }
                    label="WMS"
                    disabled={viewOnly}
                  />
                </div>
              </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6 border-none">
              <div className="flex items-center">
                <p className="font-semibold">Status</p>
              </div>
              <div className="col-span-2">
                <Switch
                  checked={formik.values.isActive}
                  onChange={() =>
                    formik.setFieldValue('isActive', !formik.values.isActive)
                  }
                  label="Active"
                  disabled={viewOnly}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NodeForm;
