/*eslint-disable*/
import { FormControl, Typography } from '@material-ui/core';
import { React, useContext, useEffect, useState } from 'react';
import TextField from '../../../../core-components/atoms/TextField';
import Autocomplete from '../../../../core-components/atoms/Autocomplete';
import Switch from '../../../../core-components/atoms/Switch';
import clsx from 'clsx';
import API from '../../../../libs/axios';
import { onError } from '../../../../libs/errorLib';
import { getCurrencySymbol, SharedContext } from '../../../../utils/common';
import { organizationTypes } from '../../constants';
import { Heading, Title } from '../../../../core-components/atoms/Text';
import { PRIMARY } from '../../../../constants/colors';
import { checkPermission } from '../../../../utils/auth';
import { toaster } from '../../../../utils/toaster';
import { checkValidationErr } from '../../../../services/BulkUploadServices';
import { FileUpload } from './FileUpload';
import AddIcon from '@mui/icons-material/Add';
import Button from '../../../../core-components/atoms/Button';
import { ORGANIZATION_TYPES } from '../../../../constants';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import FLAGS from '../../../../constants/featureFlags';
import deleteIcon from '../../../../assets/icons/deleteIcon.svg';

const listBoxProps = {
  fontSize: 14
};

const inputProps = {
  classes: {
    input: 'text-sm h-7',
    textarea: 'text-sm',
    'MuiInputBase-root': 'py-2 px-4 text-sm'
  }
};

const sxProps = {
  '& .MuiOutlinedInput-input': {
    padding: '11.6px 14px !important'
  },
  '& .MuiInputBase-root': {
    fontSize: '14px'
  },
  '& .MuiAutocomplete-input': {
    padding: '0px 4px 0px 6px !important'
  },
  '& input::placeholder': {
    fontSize: '14px'
  },
  '& textarea::placeholder': {
    fontSize: '14px'
  }
};

const ProductForm = ({
  selectedCompany,
  formik,
  customers,
  categories,
  setCategories,
  brands,
  setBrands,
  stockHandlingMechanism,
  customerSearchKeyword,
  setCustomerSearchKeyword,
  brandSearchKeyword,
  setBrandSearchKeyword,
  categorySearchKeyword,
  setCategorySearchKeyword,
  productTypeSearchKeyword,
  setProductTypeSearchKeyword,
  supplierSearchKeyword,
  setSupplierSearchKeyword,
  primaryUomSearchKeyword,
  setPrimaryUomSearchKeyword,
  secondaryUomSearchKeyword,
  setSecondaryUomSearchKeyword,
  productType,
  supplier,
  isEdit
}) => {
  const {
    organization,
    setAPILoader,
    currentUser,
    organizationType,
    settings
  } = useContext(SharedContext);
  const { isFeatureEnabled } = useFeatureFlags();
  const [uoms, setUoms] = useState([]);
  const [secondaryUoms, setSecondaryUoms] = useState([]);
  const [recordShipment, setRecordShipment] = useState(isEdit);

  useEffect(() => {
    // Debounce the API call to avoid making a request on every keystroke
    const delayDebounceFn = setTimeout(() => {
      getUoms(primaryUomSearchKeyword);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [primaryUomSearchKeyword]);

  useEffect(() => {
    // Debounce the API call to avoid making a request on every keystroke
    const delayDebounce = setTimeout(() => {
      getSecondaryUoms(secondaryUomSearchKeyword);
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [secondaryUomSearchKeyword]);

  const getUoms = async (searchKeyword, page, columns) => {
    try {
      setAPILoader(true);
      const uoms = await API.get('uoms', {
        params: { page: page, columns, search: searchKeyword }
      });
      setUoms(uoms?.data?.filter((u) => u.isActive === true) || []);
    } catch (err) {
      let errors = err.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const getSecondaryUoms = async (searchKeyword, page, columns) => {
    try {
      setAPILoader(true);
      const uoms = await API.get('uoms', {
        params: { page: page, columns, search: searchKeyword }
      });
      setSecondaryUoms(uoms?.data?.filter((u) => u.isActive === true) || []);
    } catch (err) {
      let errors = err.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const handleCreateCategoryClick = async () => {
    try {
      setAPILoader(true);
      const data = await API.post(`categories`, {
        name: categorySearchKeyword,
        isActive: true,
        companyId: selectedCompany
          ? selectedCompany?.id
          : formik.values?.selectedCustomer?.id
      });
      formik.setFieldValue('categoryId', data?.id);
      formik.setFieldValue('selectedCategory', data);
      setCategories([...categories, ...[data]]);
      toaster('success', `${categorySearchKeyword} Created Successfully.`);
    } catch (error) {
      checkValidationErr(
        error,
        'unique_category_companyId',
        'Category already exists it must be unique'
      );
    } finally {
      setAPILoader(false);
    }
  };

  const handleCreateBrandClick = async () => {
    try {
      setAPILoader(true);
      const data = await API.post(`brands`, {
        name: brandSearchKeyword,
        isActive: true,
        companyId: selectedCompany
          ? selectedCompany?.id
          : formik.values?.selectedCustomer?.id
      });
      formik.setFieldValue('brandId', data?.id);
      formik.setFieldValue('selectedBrand', data);
      setBrands([...brands, ...[data]]);
      toaster('success', `${brandSearchKeyword} Created Successfully.`);
    } catch (error) {
      checkValidationErr(
        error,
        `unique_brand_companyId`,
        `Brand already exists it must be unique`
      );
    } finally {
      setAPILoader(false);
    }
  };

  const handleCreateUomClick = async () => {
    try {
      setAPILoader(true);
      await API.post(`uoms`, {
        name: primaryUomSearchKeyword,
        isActive: true
      });
      toaster('success', 'UoM has been created.');
      getUoms(primaryUomSearchKeyword);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const handleCreateSecUomClick = async () => {
    try {
      setAPILoader(true);
      await API.post(`uoms`, {
        name: secondaryUomSearchKeyword,
        isActive: true
      });
      toaster('success', 'UoM has been created.');
      getSecondaryUoms(secondaryUomSearchKeyword);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const handleAddUoM = () => {
    const array = formik.values.uoms || [];
    array.push({ uomId: '', conversionFactor: '', UOM: { id: '', name: '' } });
    formik.setFieldValue('uoms', array);
  };

  const handleRemoveUoM = (uom) => {
    const array = formik.values.uoms || [];
    const indexToRemove = array.findIndex((item) => item?.uomId === uom?.uomId);
    const newArray = array.filter((_, index) => index !== indexToRemove);
    formik.setFieldValue('uoms', newArray);
  };

  return (
    <div className="flex justify-center px-8 pt-12 pb-16">
      <form onSubmit={formik.handleSubmit} className={'w-[970px]'}>
        <div>
          <Heading variant={'section'} className={'mb-4'}>
            Basic Product Details
          </Heading>
          <div
            className={clsx('w-full flex justify-between items-center mb-4', {
              hidden:
                selectedCompany ||
                organization?.type ===
                  organizationTypes.MANUFACTURER.toUpperCase()
            })}
          >
            <Title variant={'md'}>
              Entity
              <span className="text-error ml-2">*</span>
            </Title>
            <div className="w-[479px]">
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  id="customer"
                  options={
                    selectedCompany
                      ? [
                          {
                            id: selectedCompany?.id,
                            name: selectedCompany?.name
                          }
                        ]
                      : customers?.map((company) => ({
                          id: company?.id,
                          name: company?.name
                        }))
                  }
                  inputValue={customerSearchKeyword}
                  getOptionLabel={(customer) => customer?.name || ''}
                  placeholder="Select Entity"
                  onChange={(event, newValue) => {
                    formik.setFieldValue('companyId', newValue.id);
                    formik.setFieldValue('selectedCustomer', newValue);
                  }}
                  onInputChange={(event, newValue) => {
                    setCustomerSearchKeyword(newValue);
                  }}
                  value={
                    selectedCompany
                      ? {
                          id: selectedCompany?.id,
                          name: selectedCompany?.name
                        }
                      : formik.values?.selectedCustomer
                  }
                  sxProps={sxProps}
                  customInputProps={inputProps}
                  listBoxProps={listBoxProps}
                />
                {formik.errors?.companyId && formik.touched?.companyId ? (
                  <Typography color="error">Entity is required!</Typography>
                ) : (
                  ''
                )}
              </FormControl>
            </div>
          </div>
          <div
            className={clsx('w-full flex items-center justify-between', {
              'mb-4': selectedCompany,
              'my-4': !selectedCompany
            })}
          >
            <div className="flex items-center">
              <Title variant={'md'}>Item Name & Code</Title>
              <span className="text-error ml-2">*</span>
            </div>
            <div className="flex w-[479px] justify-between">
              <div className="w-full mr-2">
                <FormControl fullWidth={true} variant="outlined">
                  <TextField
                    placeholder="Enter Code"
                    type="text"
                    value={formik.values?.name || ''}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'name',
                        e.target.value ? e.target.value : ''
                      );
                    }}
                    size="small"
                    sxProps={sxProps}
                  />
                </FormControl>
              </div>
              <div className="w-full">
                <FormControl fullWidth={true} variant="outlined">
                  <TextField
                    placeholder="Enter Name"
                    type="text"
                    value={formik.values?.description || ''}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'description',
                        e.target.value ? e.target.value : ''
                      );
                    }}
                    size="small"
                    sxProps={sxProps}
                  />
                  {formik.errors?.description && formik.touched?.description ? (
                    <Typography color="error">Name is required!</Typography>
                  ) : (
                    ''
                  )}
                </FormControl>
              </div>
            </div>
          </div>
          <div className={'w-full flex justify-between items-center mb-4'}>
            <Title variant={'md'}>Product Type & Supplier</Title>
            <div className="flex w-[479px] justify-between">
              <div className="w-full">
                <FormControl fullWidth={true} variant="outlined">
                  <Autocomplete
                    id="productType"
                    options={productType || []}
                    inputValue={productTypeSearchKeyword}
                    getOptionLabel={(type) => type || ''}
                    placeholder="Select Raw Material, WIP or FG"
                    onChange={(event, newValue) => {
                      formik.setFieldValue('productType', newValue);
                    }}
                    onInputChange={(event, newValue) => {
                      setProductTypeSearchKeyword(newValue);
                    }}
                    value={formik.values.productType}
                    sxProps={sxProps}
                    customInputProps={inputProps}
                    listBoxProps={listBoxProps}
                  />
                </FormControl>
              </div>
              {organizationType === ORGANIZATION_TYPES.MANUFACTURER && (
                <div className="w-full ml-2">
                  <FormControl fullWidth={true} variant="outlined">
                    <Autocomplete
                      id="supplier"
                      options={supplier || []}
                      inputValue={supplierSearchKeyword}
                      value={formik.values?.selectedSupplier}
                      getOptionLabel={(supplier) => supplier?.name || ''}
                      placeholder="Select Supplier"
                      onChange={(event, newValue) => {
                        formik.setFieldValue('supplierId', newValue.id);
                        formik.setFieldValue('selectedSupplier', newValue);
                      }}
                      onInputChange={(event, newValue) => {
                        setSupplierSearchKeyword(newValue);
                      }}
                      sxProps={sxProps}
                      customInputProps={inputProps}
                      listBoxProps={listBoxProps}
                    />
                  </FormControl>
                </div>
              )}
            </div>
          </div>
          <div className="w-full flex justify-between items-center mb-4 ">
            <Title variant={'md'}>Category & Brand</Title>
            <div className="flex w-[479px] justify-between">
              <div className="w-full mr-2">
                <FormControl fullWidth={true} variant="outlined">
                  <Autocomplete
                    options={categories || []}
                    value={formik.values?.selectedCategory}
                    inputValue={categorySearchKeyword}
                    getOptionLabel={(category) => category?.name || ''}
                    placeholder="Select Category"
                    onChange={(event, newValue) => {
                      formik.setFieldValue('categoryId', newValue.id);
                      formik.setFieldValue('selectedCategory', newValue);
                    }}
                    renderOption={(props, option) => {
                      // eslint-disable-next-line no-unused-vars
                      const { className, ...rest } = props;
                      return option.type === 'add' ? (
                        <li
                          className="auto-complete-option"
                          style={{
                            color: PRIMARY,
                            fontSize: '16px',
                            fontWeight: 500
                          }}
                          onClick={handleCreateCategoryClick}
                        >
                          {/* eslint-disable-next-line react/no-unescaped-entities */}
                          + Create "{categorySearchKeyword}"
                        </li>
                      ) : (
                        <li {...rest} className="auto-complete-option-small">
                          <span>{option.name}</span>
                        </li>
                      );
                    }}
                    filterOptions={(options, state) => {
                      const filtered = options.filter(
                        (option) =>
                          option.name
                            ?.toLowerCase()
                            ?.includes(state.inputValue.toLowerCase()) ||
                          option.label
                      );
                      return filtered.length
                        ? filtered
                        : checkPermission(
                            currentUser,
                            'OPS_CATEGORY_CREATE'
                          ) && [{ type: 'add', label: categorySearchKeyword }];
                    }}
                    onInputChange={(event, newValue) => {
                      setCategorySearchKeyword(newValue);
                    }}
                    sxProps={sxProps}
                    customInputProps={inputProps}
                    listBoxProps={listBoxProps}
                  />
                </FormControl>
              </div>
              <div className="w-full">
                <FormControl fullWidth={true} variant="outlined">
                  <Autocomplete
                    options={brands || []}
                    renderOption={(props, option) => {
                      // eslint-disable-next-line no-unused-vars
                      const { className, ...rest } = props;
                      return option.type === 'add' ? (
                        <li
                          className="auto-complete-option"
                          style={{
                            color: PRIMARY,
                            fontSize: '16px',
                            fontWeight: 500
                          }}
                          onClick={handleCreateBrandClick}
                        >
                          {/* eslint-disable-next-line react/no-unescaped-entities */}
                          + Create "{brandSearchKeyword}"
                        </li>
                      ) : (
                        <li {...rest} className="auto-complete-option-small">
                          <span>{option.name}</span>
                        </li>
                      );
                    }}
                    inputValue={brandSearchKeyword}
                    value={formik.values?.selectedBrand}
                    getOptionLabel={(brand) => brand.name || ''}
                    placeholder="Select Brand"
                    onChange={(event, newValue) => {
                      formik.setFieldValue('brandId', newValue.id);
                      formik.setFieldValue('selectedBrand', newValue);
                    }}
                    filterOptions={(options, state) => {
                      const filtered = options.filter(
                        (option) =>
                          option.name
                            ?.toLowerCase()
                            ?.includes(state.inputValue.toLowerCase()) ||
                          option.label
                      );
                      return filtered.length
                        ? filtered
                        : checkPermission(currentUser, 'OPS_BRAND_CREATE') && [
                            { type: 'add', label: brandSearchKeyword }
                          ];
                    }}
                    onInputChange={(event, newValue) => {
                      setBrandSearchKeyword(newValue);
                    }}
                    sxProps={sxProps}
                    customInputProps={inputProps}
                    listBoxProps={listBoxProps}
                  />
                </FormControl>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-between items-center mb-4 border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 pb-4">
            <Title variant={'md'}>Barcode & HS Code</Title>
            <div className="flex w-[479px] justify-between">
              <div className="w-full mr-2">
                <FormControl fullWidth={true} variant="outlined">
                  <TextField
                    placeholder="Enter Barcode"
                    type="text"
                    value={formik.values?.barcode || ''}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'barcode',
                        e.target.value ? e.target.value : ''
                      );
                    }}
                    size="small"
                    sxProps={sxProps}
                  />
                </FormControl>
              </div>
              <div className="w-full">
                <FormControl fullWidth={true} variant="outlined">
                  <TextField
                    placeholder="Enter HS Code"
                    type="text"
                    value={formik.values?.hsCode || ''}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'hsCode',
                        e.target.value ? e.target.value : ''
                      );
                    }}
                    size="small"
                    sxProps={sxProps}
                  />
                </FormControl>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-between items-center mb-4">
            <Title variant={'md'}>
              Primary Unit of Measure (UoM)
              <span className="text-error ml-2">*</span>
            </Title>
            <div className="flex w-[479px] justify-between">
              <div className="w-full">
                <FormControl fullWidth={true} variant="outlined">
                  <Autocomplete
                    options={uoms || []}
                    value={formik.values?.selectedUom}
                    getOptionLabel={(uom) => uom.name || ''}
                    renderOption={(props, option) => {
                      // eslint-disable-next-line no-unused-vars
                      const { className, ...rest } = props;
                      return option.type === 'add' ? (
                        <li
                          className="auto-complete-option"
                          style={{
                            color: PRIMARY,
                            fontSize: '16px',
                            fontWeight: 500
                          }}
                          onClick={handleCreateUomClick}
                        >
                          {/* eslint-disable-next-line react/no-unescaped-entities */}
                          + Create "{primaryUomSearchKeyword}"
                        </li>
                      ) : (
                        <li {...rest} className="auto-complete-option-small">
                          <span>{option.name}</span>
                        </li>
                      );
                    }}
                    filterOptions={(options, state) => {
                      const filtered = options.filter(
                        (option) =>
                          option.name
                            ?.toLowerCase()
                            ?.includes(state.inputValue.toLowerCase()) ||
                          option.label
                      );
                      return filtered.length
                        ? filtered
                        : checkPermission(currentUser, 'OPS_UOM_CREATE') && [
                            { type: 'add', label: primaryUomSearchKeyword }
                          ];
                    }}
                    onInputChange={(event, newValue) => {
                      setPrimaryUomSearchKeyword(newValue);
                    }}
                    placeholder="Add Primary UoM"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        formik.setFieldValue('uomId', newValue.id);
                        formik.setFieldValue('selectedUom', newValue);
                      }
                    }}
                    sxProps={sxProps}
                    customInputProps={inputProps}
                    listBoxProps={listBoxProps}
                  />
                  {formik.errors?.uomId && formik.touched?.uomId ? (
                    <Typography color="error">UoM is required!</Typography>
                  ) : (
                    ''
                  )}
                </FormControl>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-between items-center">
            <Title variant={'md'}>Secondary UoM & Conversion</Title>
            <div>
              {formik.values?.uoms?.map((uom, index) => (
                <div
                  key={index}
                  className="flex w-[479px] justify-between mb-4 items-center"
                >
                  <div className="w-full mr-2">
                    <FormControl fullWidth={true} variant="outlined">
                      <Autocomplete
                        options={
                          secondaryUoms?.filter(
                            (unit) =>
                              !formik.values?.uoms?.some(
                                (filter) => filter.uomId === unit.id
                              )
                          ) || []
                        }
                        value={uom.UOM}
                        getOptionLabel={(uom) => uom.name || ''}
                        renderOption={(props, option) => {
                          // eslint-disable-next-line no-unused-vars
                          const { className, ...rest } = props;
                          return option.type === 'add' ? (
                            <li
                              className="auto-complete-option"
                              style={{
                                color: PRIMARY,
                                fontSize: '16px',
                                fontWeight: 500
                              }}
                              onClick={handleCreateSecUomClick}
                            >
                              {/* eslint-disable-next-line react/no-unescaped-entities */}
                              + Create "{secondaryUomSearchKeyword}"
                            </li>
                          ) : (
                            <li
                              {...rest}
                              className="auto-complete-option-small"
                            >
                              <span>{option.name}</span>
                            </li>
                          );
                        }}
                        filterOptions={(options, state) => {
                          const filtered = options.filter(
                            (option) =>
                              option.name
                                ?.toLowerCase()
                                ?.includes(state.inputValue.toLowerCase()) ||
                              option.label
                          );
                          return filtered.length
                            ? filtered
                            : checkPermission(
                                currentUser,
                                'OPS_UOM_CREATE'
                              ) && [
                                {
                                  type: 'add',
                                  label: secondaryUomSearchKeyword
                                }
                              ];
                        }}
                        onInputChange={(event, newValue) => {
                          setSecondaryUomSearchKeyword(newValue);
                        }}
                        placeholder="Add Secondary UoM"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            const values = [...formik.values.uoms];
                            values[index]['uomId'] = newValue.id;
                            values[index]['UOM']['id'] = newValue.id;
                            values[index]['UOM']['name'] = newValue.name;
                            formik.setFieldValue('uoms', values);
                          }
                        }}
                        disabled={uom?.deletable === false}
                        sxProps={sxProps}
                        customInputProps={inputProps}
                        listBoxProps={listBoxProps}
                      />
                    </FormControl>
                  </div>
                  <div className="w-full">
                    <FormControl fullWidth={true} variant="outlined">
                      <TextField
                        placeholder="Conversion Rate"
                        type="number"
                        value={uom?.conversionFactor || ''}
                        onChange={(event) => {
                          const values = [...formik.values.uoms];
                          values[index]['conversionFactor'] =
                            event.target.value;
                          formik.setFieldValue('uoms', values);
                        }}
                        disabled={uom?.deletable === false}
                        size="small"
                        sxProps={sxProps}
                      />
                    </FormControl>
                  </div>
                  {uom?.deletable !== false && (
                    <div className="ml-2 w-10">
                      <button
                        onClick={async (event) => {
                          event.stopPropagation();
                          handleRemoveUoM(uom);
                        }}
                      >
                        <img src={deleteIcon} alt="delete-icon" />
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full flex justify-end items-end border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 pb-4">
            <Button
              onClick={handleAddUoM}
              icon={<AddIcon />}
              variant="text"
              label={'Add More'}
              color="primary"
            />
          </div>
          <div className={'w-full flex justify-between my-4'}>
            <Title variant={'md'}>Enter Additional information</Title>
            <div className="flex w-full max-w-[479px]">
              <FormControl fullWidth variant="outlined">
                <TextField
                  value={formik.values.additionalInformation}
                  onChange={(e) =>
                    formik.setFieldValue(
                      'additionalInformation',
                      e.target.value
                    )
                  }
                  sxProps={{
                    '& input::placeholder': {
                      fontSize: '14px'
                    },
                    '& textarea::placeholder': {
                      fontSize: '14px'
                    }
                  }}
                  placeholder="Type here"
                  multiline
                  rows={2}
                />
              </FormControl>
            </div>
          </div>
          <div className={'w-full flex justify-between my-4'}>
            <div className={'flex flex-col gap-1'}>
              <Title variant={'md'}>Upload Product Image</Title>
              <p className="text-xs">
                The images should be at least 500 x 500 px to avoid blurriness
                when zooming in and the aspect ratio should be 1:1 and max 1mb
                size
              </p>
            </div>
            <div className="flex w-full max-w-[479px]">
              <FileUpload formik={formik} propertyName="image" />
            </div>
          </div>
          <div className="w-full flex justify-between items-center mt-10 mb-4">
            <Title variant={'md'}>Batch/Expiry & MRP</Title>
            <div className="flex w-[479px] justify-between">
              <div className="w-full mr-2">
                <FormControl fullWidth={true} variant="outlined">
                  <Switch
                    checked={formik.values?.batchEnabled}
                    onChange={(newValue) => {
                      formik.setFieldValue('batchEnabled', newValue);
                    }}
                    disabled={
                      !isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                    }
                    label="Enable Batch/Expiry"
                  ></Switch>
                </FormControl>
              </div>
              <div className="w-full">
                <FormControl fullWidth={true} variant="outlined">
                  <Switch
                    checked={formik?.values?.MRPEnabled}
                    onChange={(newValue) => {
                      formik.setFieldValue('MRPEnabled', newValue);
                    }}
                    disabled={
                      !isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                    }
                    label="Enable MRP"
                  ></Switch>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 pb-4">
            <Title variant={'md'}>Status</Title>
            <div className="w-[479px]">
              <FormControl fullWidth={true} variant="outlined">
                <Switch
                  checked={formik.values?.isActive}
                  onChange={(newValue) => {
                    formik.setFieldValue('isActive', newValue);
                  }}
                  label="Active"
                ></Switch>
              </FormControl>
            </div>
          </div>
          <div className="w-full flex justify-between pt-4 item-center mb-4">
            <Title variant={'md'}>
              Do you want to record shipping and inventory details?
            </Title>
            <div className="w-[479px]">
              <FormControl fullWidth={true} variant="outlined">
                <Switch
                  checked={recordShipment}
                  onChange={(newValue) => {
                    setRecordShipment(newValue);
                  }}
                  label={recordShipment ? 'Yes' : 'No'}
                ></Switch>
              </FormControl>
            </div>
          </div>
          {recordShipment && (
            <div>
              <Heading variant={'section'} className={'mb-4'}>
                Shipping Details
              </Heading>
              <div className="w-full flex justify-between items-center mb-4">
                <Title variant={'md'}>Dimensions</Title>
                <div className="flex w-[479px] justify-between">
                  <div className="w-full mr-2">
                    <FormControl fullWidth={true} variant="outlined">
                      <TextField
                        placeholder="Enter Height"
                        type="number"
                        value={formik.values?.height || ''}
                        onChange={(e) => {
                          e.target.value =
                            e.target.value < 0 ? 0 : e.target.value;
                          formik.setFieldValue(
                            'height',
                            e.target.value ? e.target.value : ''
                          );
                          if (formik.values?.length && formik.values?.breadth) {
                            formik.setFieldValue(
                              'dimensionsCBM',
                              e.target.value *
                                formik.values?.length *
                                formik.values?.breadth
                            );
                          }
                        }}
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <span className="whitespace-nowrap">
                              {settings?.size?.symbol || 'cm'}
                            </span>
                          )
                        }}
                        sxProps={sxProps}
                      />
                    </FormControl>
                  </div>
                  <div className="w-full mr-2">
                    <FormControl fullWidth={true} variant="outlined">
                      <TextField
                        placeholder="Enter Length"
                        type="number"
                        value={formik.values?.length || ''}
                        onChange={(e) => {
                          e.target.value =
                            e.target.value < 0 ? 0 : e.target.value;
                          formik.setFieldValue(
                            'length',
                            e.target.value ? e.target.value : ''
                          );
                          if (formik.values?.height && formik.values?.breadth) {
                            formik.setFieldValue(
                              'dimensionsCBM',
                              formik.values?.height *
                                e.target.value *
                                formik.values?.breadth
                            );
                          }
                        }}
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <span className="whitespace-nowrap">
                              {settings?.size?.symbol || 'cm'}
                            </span>
                          )
                        }}
                        sxProps={sxProps}
                      />
                    </FormControl>
                  </div>
                  <div className="w-full">
                    <FormControl fullWidth={true} variant="outlined">
                      <TextField
                        placeholder="Enter Breadth"
                        type="number"
                        value={formik.values?.breadth || ''}
                        onChange={(e) => {
                          e.target.value =
                            e.target.value < 0 ? 0 : e.target.value;
                          formik.setFieldValue(
                            'breadth',
                            e.target.value ? e.target.value : ''
                          );
                          if (formik.values?.height && formik.values?.length) {
                            formik.setFieldValue(
                              'dimensionsCBM',
                              formik.values?.height *
                                formik.values?.length *
                                e.target.value
                            );
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <span className="whitespace-nowrap">
                              {settings?.size?.symbol || 'cm'}
                            </span>
                          )
                        }}
                        size="small"
                        sxProps={sxProps}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-between items-center mb-4">
                <Title variant={'md'}>Volume & Weight</Title>
                <div className="flex w-[479px] justify-between">
                  <div className="w-full mr-2">
                    <FormControl fullWidth={true} variant="outlined">
                      <TextField
                        name="dimensionsCBM"
                        placeholder="Enter Volume"
                        type="number"
                        value={formik.values?.dimensionsCBM || 0}
                        InputProps={{
                          endAdornment: (
                            <span className="whitespace-nowrap">
                              {settings?.volume?.symbol || 'cm³'}
                            </span>
                          )
                        }}
                        onChange={(e) => {
                          e.target.value =
                            e.target.value < 0 ? 0 : e.target.value;
                          formik.setFieldValue(
                            'dimensionsCBM',
                            e.target.value ? e.target.value : ''
                          );
                        }}
                        size="small"
                        sxProps={sxProps}
                      />
                    </FormControl>
                  </div>
                  <div className="w-full">
                    <FormControl fullWidth={true} variant="outlined">
                      <TextField
                        placeholder="Enter Weight"
                        type="number"
                        value={formik.values?.weight || ''}
                        onChange={(e) => {
                          e.target.value =
                            e.target.value < 0 ? 0 : e.target.value;
                          formik.setFieldValue(
                            'weight',
                            e.target.value ? e.target.value : ''
                          );
                        }}
                        InputProps={{
                          endAdornment: (
                            <span className="whitespace-nowrap">
                              {settings?.weight?.symbol || 'kg'}
                            </span>
                          )
                        }}
                        size="small"
                        sxProps={sxProps}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-between items-center mb-4">
                <Title variant={'md'}>Lead Time</Title>
                <div className="w-[479px]">
                  <FormControl fullWidth={true} variant="outlined">
                    <TextField
                      placeholder="Enter Lead Time"
                      type="number"
                      value={formik.values?.leadTime || ''}
                      onChange={(e) => {
                        e.target.value =
                          e.target.value < 0 ? 0 : e.target.value;
                        formik.setFieldValue(
                          'leadTime',
                          e.target.value ? e.target.value : ''
                        );
                      }}
                      InputProps={{
                        endAdornment: (
                          <span className="whitespace-nowrap">Days</span>
                        )
                      }}
                      size="small"
                      sxProps={sxProps}
                    />
                  </FormControl>
                </div>
              </div>
              <Heading variant={'section'} className={'mb-4'}>
                Inventory Details
              </Heading>
              <div className="w-full flex justify-between items-center my-4">
                <Title variant={'md'}>Min & Max Order Quantity</Title>
                <div className="flex w-[479px] justify-between">
                  <div className="w-full mr-2">
                    <FormControl fullWidth={true} variant="outlined">
                      <TextField
                        placeholder="Enter Min Quantity"
                        type="number"
                        value={formik.values?.minOrderedQuantity || ''}
                        onChange={(e) => {
                          e.target.value =
                            e.target.value < 0 ? 0 : e.target.value;
                          formik.setFieldValue(
                            'minOrderedQuantity',
                            e.target.value ? e.target.value : ''
                          );
                        }}
                        InputProps={{
                          endAdornment: (
                            <span className="whitespace-nowrap">Pcs</span>
                          )
                        }}
                        size="small"
                        sxProps={sxProps}
                      />
                    </FormControl>
                  </div>
                  <div className="w-full">
                    <FormControl fullWidth={true} variant="outlined">
                      <TextField
                        placeholder="Enter Max Quantity"
                        type="number"
                        value={formik.values?.maxOrderedQuantity || ''}
                        onChange={(e) => {
                          e.target.value =
                            e.target.value < 0 ? 0 : e.target.value;
                          formik.setFieldValue(
                            'maxOrderedQuantity',
                            e.target.value ? e.target.value : ''
                          );
                        }}
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <span className="whitespace-nowrap">Pcs</span>
                          )
                        }}
                        sxProps={sxProps}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-between items-center my-4">
                <Title variant={'md'}>
                  Re-Order Point & Stock Holding Mechanism
                </Title>
                <div className="flex w-[479px] justify-between">
                  <div className="w-full mr-2">
                    <FormControl fullWidth={true} variant="outlined">
                      <TextField
                        placeholder="Enter Reorder Point"
                        type="number"
                        value={formik.values?.reOrderPoint || ''}
                        onChange={(e) => {
                          e.target.value =
                            e.target.value < 0 ? 0 : e.target.value;
                          formik.setFieldValue(
                            'reOrderPoint',
                            e.target.value ? e.target.value : ''
                          );
                        }}
                        InputProps={{
                          endAdornment: (
                            <span className="whitespace-nowrap">Pcs</span>
                          )
                        }}
                        size="small"
                        sxProps={sxProps}
                      />
                    </FormControl>
                  </div>
                  <div className="w-full">
                    <FormControl fullWidth={true} variant="outlined">
                      <Autocomplete
                        options={stockHandlingMechanism}
                        value={formik.values?.stockHandlingType}
                        placeholder="Select Stock Handling"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            formik.setFieldValue('stockHandlingType', newValue);
                          }
                        }}
                        sxProps={sxProps}
                        customInputProps={inputProps}
                        listBoxProps={listBoxProps}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
              {isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING) && (
                <div className="w-full flex justify-between items-center my-4">
                  <Title variant={'md'}>Product & Shelf Life</Title>
                  <div className="flex w-[479px] justify-between">
                    <div className="w-full mr-2">
                      <FormControl fullWidth={true} variant="outlined">
                        <TextField
                          placeholder="Enter Shelf Life"
                          type="number"
                          value={formik.values?.shelfLife || ''}
                          onChange={(e) => {
                            e.target.value =
                              e.target.value < 0 ? 0 : e.target.value;
                            formik.setFieldValue(
                              'shelfLife',
                              e.target.value ? e.target.value : ''
                            );
                          }}
                          InputProps={{
                            endAdornment: (
                              <span className="whitespace-nowrap">Days</span>
                            )
                          }}
                          size="small"
                          sxProps={sxProps}
                        />
                      </FormControl>
                    </div>
                    <div className="w-full">
                      <FormControl fullWidth={true} variant="outlined">
                        <TextField
                          placeholder="Enter Product Life"
                          type="number"
                          value={formik.values?.productLife || ''}
                          onChange={(e) => {
                            e.target.value =
                              e.target.value < 0 ? 0 : e.target.value;
                            formik.setFieldValue(
                              'productLife',
                              e.target.value ? e.target.value : ''
                            );
                          }}
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <span className="whitespace-nowrap">Days</span>
                            )
                          }}
                          sxProps={sxProps}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              )}
              {organizationType === ORGANIZATION_TYPES.MANUFACTURER && (
                <div className="w-full flex justify-between items-center my-4">
                  <Title variant={'md'}>Cost & Selling Price</Title>
                  <div className="flex w-[479px] justify-between">
                    <div className="w-full mr-2">
                      <FormControl fullWidth={true} variant="outlined">
                        <TextField
                          placeholder="Cost Price"
                          type="number"
                          value={formik.values?.costPrice || ''}
                          onChange={(e) => {
                            e.target.value =
                              e.target.value < 0 ? 0 : e.target.value;
                            formik.setFieldValue(
                              'costPrice',
                              e.target.value ? e.target.value : ''
                            );
                          }}
                          InputProps={{
                            endAdornment: (
                              <span className="whitespace-nowrap">
                                {getCurrencySymbol(
                                  organization?.defaultCurrency
                                )}
                              </span>
                            )
                          }}
                          size="small"
                          sxProps={sxProps}
                        />
                      </FormControl>
                    </div>
                    <div className="w-full">
                      <FormControl fullWidth={true} variant="outlined">
                        <TextField
                          placeholder="Selling Price"
                          type="number"
                          value={formik.values?.sellingPrice || ''}
                          onChange={(e) => {
                            e.target.value =
                              e.target.value < 0 ? 0 : e.target.value;
                            formik.setFieldValue(
                              'sellingPrice',
                              e.target.value ? e.target.value : ''
                            );
                          }}
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <span className="whitespace-nowrap">
                                {getCurrencySymbol(
                                  organization?.defaultCurrency
                                )}
                              </span>
                            )
                          }}
                          sxProps={sxProps}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default ProductForm;
