import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  FormHelperText
} from '@material-ui/core';
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  SharedContext,
  dateFormat,
  productLabelFormat,
  reasonLabelFormat
} from '../../../utils/common';
import PrintIcon from '@material-ui/icons/Print';
import { useNavigate, useParams } from 'react-router';
import owareLogo from '../../../assets/icons/O360DarkLogo.svg';

import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles(() => ({
  parentContainer: {
    boxSizing: 'border-box',
    padding: '30px 30px',
    width: '100% !important',
    margin: '0px !important',
    '@media (max-width: 767px)': {
      padding: '0px !important'
    }
  },
  parentContainerForPrint: {
    boxSizing: 'border-box',
    padding: '30px 0px'
  },
  pageHeading: {
    fontWeight: 600,
    fontSize: '16px'
  },
  pageSubHeading: {
    fontWeight: 300
  },
  heading: {
    fontWeight: 'bolder'
  },
  shadedTableHeader: {
    backgroundColor: 'rgba(202,201,201,0.3)'
  },
  tableHeadText: {
    background: 'transparent',
    fontWeight: 'bolder',
    fontSize: '12px'
  },
  tableRow: {
    '&:last-child th, &:last-child td': {
      borderBottom: 0
    }
  },
  customWidth: {
    maxWidth: 500,
    fontSize: 14
  },
  commentWrapper: {
    width: 105,
    maxWidth: 105,
    // display: "inline-block",
    // whiteSpace: 'normal',
    wordWrap: 'break-word'
  },
  tableBatchRow: {
    '&:last-child th, &:last-child td': {
      borderBottom: '0.25px solid #000000 !important'
    }
  },
  subHeading: {
    fontWeight: 600,
    fontSize: '12px'
  },
  subHeadingMargin: {
    fontWeight: 600,
    fontSize: '12px',
    marginTop: -15
  },
  valueMargin: {
    fontSize: '12px',
    marginTop: -15
  },
  value: {
    fontSize: '12px',
    overflow: 'hidden'
  },
  valueMid: {
    border: 'none',
    fontSize: '12px'
  },
  topColumn: {
    fontWeight: 600,
    fontSize: '12px'
  },
  topColumn1: {
    fontWeight: 600,
    fontSize: '12px',
    textAlign: 'center'
  },
  tablePadding: {
    // padding: "4px !important",
    height: '20px !important'
  }
}));

function ViewStockManagementDetails() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { darkOrganizationLogoFile } = useContext(SharedContext);

  const productsTopColumnsForPrint = [
    {
      id: 'warehouseName',
      label: 'WAREHOUSE',
      minWidth: 'auto',
      // colSpan: "3",
      className: classes.topColumn,
      format: (inventory) => inventory?.Warehouse?.businessWarehouseCode
    },
    {
      id: 'productName',
      label: 'PRODUCT',
      minWidth: 'auto',
      // colSpan: "3",
      className: classes.topColumn,
      format: (inventory) => productLabelFormat(inventory?.Product)
    },
    {
      id: 'UOM',
      label: 'UoM',
      minWidth: 'auto',
      // colSpan: "3",
      className: classes.topColumn,
      format: (inventory) => inventory?.Product?.UOM?.name
    }
  ];

  const productsBottomColumnsForPrint = [
    // {
    //   id: "batchName",
    //   label: "BATCH NAME",
    //   minWidth: "auto",
    //   className: "",
    //   format: ( batch) =>
    //     batch.InventoryDetail.batchName.includes("default")
    //       ? "-"
    //       : batch.InventoryDetail.batchName,
    // },
    {
      id: 'batchNumber',
      label: 'BATCH NUMBER',
      minWidth: 'auto',
      className: '',
      format: (batch) => batch.InventoryDetail.batchNumber || '-'
    },
    {
      id: 'Section',
      label: 'Section',
      minWidth: 'auto',
      className: '',
      format: (batch) => batch?.WarehouseHall?.name
    },
    {
      id: 'quantity',
      label: 'QUANTITY',
      minWidth: 'auto',
      className: '',
      format: (batch) => batch.quantity
    },
    {
      id: 'reason',
      label: 'REASON',
      minWidth: 'auto',
      className: '',
      format: (batch) =>
        reasonLabelFormat({ name: batch.WastagesType.name }, true)
    }
  ];

  const productsColumns = [
    {
      id: 'warehouseName',
      label: 'WAREHOUSE',
      minWidth: 'auto',
      className: '',
      format: (value, inventory) => inventory.Warehouse?.businessWarehouseCode
    },
    {
      id: 'Section',
      label: 'Section',
      minWidth: 'auto',
      className: '',
      format: (value, inventory, batch) => batch?.WarehouseHall?.name
    },
    {
      id: 'productName',
      label: 'PRODUCTS',
      minWidth: 'auto',
      className: '',
      format: (value, inventory) => productLabelFormat(inventory?.Product)
    },
    {
      id: 'batchName',
      label: 'BATCH NAME',
      minWidth: 'auto',
      className: '',
      format: (value, inventory, batch) =>
        batch.InventoryDetail?.batchName?.includes('default')
          ? '-'
          : batch.InventoryDetail?.batchName
    },
    {
      id: 'batchNumber',
      label: 'BATCH# + PALLET ID ',
      minWidth: 'auto',
      className: '',
      format: (value, inventory, batch) =>
        `${batch.InventoryDetail.batchNumber}${
          batch?.InventoryDetail?.Pallet?.palletId ? ',' : ''
        } ${batch?.InventoryDetail?.Pallet?.palletId || ''}` || '-'
    },
    {
      id: 'quantity',
      label: 'QUANTITY',
      minWidth: 'auto',
      className: '',
      format: (value, inventory, batch) => batch.quantity
    },
    {
      id: 'reason',
      label: 'REASON',
      minWidth: 'auto',
      className: '',
      format: (value, inventory, batch) =>
        reasonLabelFormat({ name: batch.WastagesType.name })
    },
    {
      id: 'comment',
      label: 'COMMENT',
      minWidth: 'auto',
      className: '',
      format: (value, inventory, batch) => batch.comment || '-'
    },
    {
      id: 'UOM',
      label: 'UoM',
      minWidth: 'auto',
      className: '',
      format: (value, inventory) => inventory.Product.UOM.name
    }
  ];

  const { uid } = useParams();
  const [selectedInventoryWastages, setSelectedInventoryWastages] =
    useState(null); // selected one to view
  const componentRef = useRef(); // for printing

  // If uid exists than fetch details of the selecteInventoryWastages
  useEffect(() => {
    if (uid) _getInventoryWastage(); // only in case of edit
    mixpanel.track('View Stock Adjustment Details', { stockId: uid });
  }, [uid]);

  const _getInventoryWastage = async () => {
    // axios
    //   .get(getURL(`inventory-wastages/${uid}`))
    //   .then((res) => {
    //     setSelectedInventoryWastages(res.data.data);
    //   })
    //   .catch((error) => {
    //     console.info(error);
    //   });
    try {
      const { stockAdjustment } = await API.get(`stock-adjustments/${uid}`);
      setSelectedInventoryWastages(stockAdjustment);
    } catch (err) {
      onError(err);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return selectedInventoryWastages ? (
    <>
      {/* Only for printing */}
      <Box display="none" displayPrint="block" ref={componentRef}>
        <Box
          display="none"
          displayPrint="block"
          style={{ padding: '5mm 5mm 0mm 5mm' }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              style={{
                marginTop: -13
              }}
            ></Grid>
            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={4}
              style={{
                marginTop: -5
                // marginRights: 17,
                // textAlign: "center",
                // backgroundColor: "white",
                // outlineStyle: "solid",
              }}
            >
              {/* <Typography
                      style={{ color: "black", fontSize: 16, fontWeight: 600 }}
                    >
                      CUSTOMER COPY
                    </Typography> */}
            </Grid>

            <Grid
              item
              xs={6}
              style={{
                marginTop: -25,
                marginBottom: 20
              }}
            >
              {darkOrganizationLogoFile ? (
                <img
                  src={darkOrganizationLogoFile.location}
                  alt="Logo"
                  style={{ width: '100px', height: 'auto', maxHeight: '100px' }}
                />
              ) : (
                <img
                  style={{ width: 'auto', height: '18px' }}
                  src={owareLogo}
                />
              )}
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                // marginTop: 25,
                textAlign: 'right',
                fontSize: 16,
                fontWeight: 600
                // backgroundColor: "green",
              }}
            >
              {`Date: ${dateFormat(selectedInventoryWastages.createdAt)}`}
            </Grid>
          </Grid>
          <Grid container spacing={2} xs={12} style={{ marginTop: -26 }}>
            <Grid item xs={4}>
              <Typography variant="h3" className={classes.pageHeading}>
                Stock Adjustment Details
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h3"
                className={classes.pageHeading}
                style={{ textAlign: 'right' }}
              >
                {selectedInventoryWastages.internalIdForBusiness}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          display="none"
          displayPrint="block"
          style={{ padding: '5mm 5mm 0mm 5mm' }}
        >
          <Grid container spacing={2} style={{ marginTop: -20 }}>
            <Grid item xs={3} className={classes.subHeading}>
              <Box display="block" displayPrint="block">
                Adjustment Date :
              </Box>
            </Grid>
            <Grid item xs={3} className={classes.value}>
              <Box display="block" displayPrint="block">
                {selectedInventoryWastages
                  ? dateFormat(selectedInventoryWastages.updatedAt)
                  : '-'}
              </Box>
            </Grid>
            <Grid item xs={3} className={classes.subHeading}>
              <Box display="block" displayPrint="block">
                No. Of Products :
              </Box>
            </Grid>
            <Grid item xs={3} className={classes.value}>
              <Box display="block" displayPrint="block">
                {selectedInventoryWastages &&
                selectedInventoryWastages.AdjustmentInventory
                  ? selectedInventoryWastages.AdjustmentInventory.length
                  : '-'}
              </Box>
            </Grid>
            <Grid item xs={3} className={classes.subHeadingMargin}>
              <Box display="block" displayPrint="block">
                Created By :
              </Box>
            </Grid>
            <Grid item xs={3} className={classes.valueMargin}>
              <Box display="block" displayPrint="block">
                {selectedInventoryWastages && selectedInventoryWastages.User
                  ? selectedInventoryWastages.User.firstName +
                    selectedInventoryWastages.User.lastName
                  : '-'}
              </Box>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: -12 }}></Grid>
          </Grid>

          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={
                {
                  // display: "table-row-group",
                }
              }
            >
              <TableHead style={{ display: 'table-row-group' }}>
                <TableRow className={classes.shadedTableHeader}>
                  {productsTopColumnsForPrint.map((column) => (
                    <TableCell
                      key={column.id}
                      // align={column.align}
                      // colSpan={column.colSpan}
                      className={column.className}
                      style={{
                        minWidth: column.minWidth,
                        background: 'transparent',
                        fontWeight: 'bolder',
                        fontSize: '12px',
                        lineHeight: '12px'
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {/* <TableBody> */}
              {selectedInventoryWastages.AdjustmentInventory.map(
                (inventoryWastage) => {
                  return inventoryWastage.StockAdjustmentBatch.map((batch) => {
                    return (
                      <>
                        <TableBody>
                          <TableRow style={{ breakInside: 'avoid' }}>
                            {productsTopColumnsForPrint.map((column) => {
                              // const value = inventoryWastage[column.id];
                              // return column.id !== "comment" ?
                              return (
                                <TableCell
                                  key={column.id}
                                  // align={column.align}
                                  style={{
                                    borderBottom: '0.25px solid #cecece',
                                    lineHeight: '12px'
                                  }}
                                  className={column.className}
                                >
                                  {column.format(inventoryWastage.Inventory)}
                                </TableCell>
                              );
                              // ) : (
                              //   ""
                              // );
                            })}
                          </TableRow>
                        </TableBody>
                        <TableHead style={{ display: 'table-row-group' }}>
                          <TableRow
                            style={{ breakInside: 'avoid' }}
                            // className={classes.tableRow}
                          >
                            {productsBottomColumnsForPrint.map(
                              (columnBottom) => {
                                return (
                                  <TableCell
                                    key={columnBottom.id}
                                    // align={column.align}
                                    // colSpan={column.colSpan}
                                    className={columnBottom.className}
                                    style={{
                                      minWidth: columnBottom.minWidth,
                                      background: 'transparent',
                                      fontWeight: 'bolder',
                                      fontSize: '12px',
                                      lineHeight: '12px'
                                    }}
                                  >
                                    {columnBottom.label}
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            className={classes.tableBatchRow}
                            style={{ breakInside: 'avoid' }}
                            // key={idx}
                          >
                            {productsBottomColumnsForPrint.map(
                              (columnBottom) => {
                                const value = inventoryWastage[columnBottom.id];
                                return columnBottom.id !== 'comment' ? (
                                  <>
                                    <TableCell
                                      key={columnBottom.id}
                                      // align={column.align}
                                      style={{
                                        borderBottom: '0.25px solid #cecece',
                                        lineHeight: '12px'
                                      }}
                                      // className={
                                      //   columnBottom.className
                                      // }
                                    >
                                      {/* {columnBottom.format(batch)} */}
                                      {columnBottom.format
                                        ? columnBottom.format(batch)
                                        : value}
                                    </TableCell>
                                  </>
                                ) : (
                                  ''
                                );
                              }
                            )}
                          </TableRow>
                          <TableRow className={classes.tableBatchRow}>
                            <TableCell
                              // align="right"
                              style={{ fontWeight: 600, lineHeight: '12px' }}
                            >
                              Comment
                            </TableCell>
                            <TableCell
                              style={{ lineHeight: '12px' }}
                              colSpan={6}
                            >
                              {' '}
                              {batch.comment ? batch.comment : '-'}{' '}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </>
                    );
                  });
                }
              )}
            </Table>
          </TableContainer>
        </Box>
        <FormHelperText
          style={{
            // textAlign: "center",
            position: 'fixed',
            bottom: 0,
            display: 'table-footer-group',
            pageBreakInside: 'avoid',
            fontSize: 10,
            fontWeight: 'bolder',
            color: 'black',
            marginLeft: 200
          }}
        >
          This is electronically generated document and does not require
          signature
        </FormHelperText>
      </Box>
      {/* Only for Displaying */}
      <Grid container className={classes.parentContainer} spacing={3}>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item xs={8} sm={11}>
            <Typography variant="h3" className={classes.heading}>
              Stock Adjustment Details
              <IconButton aria-label="print" onClick={handlePrint}>
                <Tooltip title="Print Stock Adjustment Details">
                  <PrintIcon />
                </Tooltip>
              </IconButton>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={1}>
            <Tooltip title="Back To Stock Adjustment List">
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/operations/stock-adjustment')}
              >
                Back
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <TableContainer className={classes.parentContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableHeadText}>
                  ADJUSTMENT ID
                </TableCell>
                <TableCell className={classes.tableHeadText}>
                  ADJUSTMENT DATE
                </TableCell>
                <TableCell className={classes.tableHeadText}>
                  NO. OF PRODUCTS
                </TableCell>
                <TableCell className={classes.tableHeadText}>
                  CREATED BY
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow className={classes.tableRow}>
                <TableCell>{selectedInventoryWastages.customId}</TableCell>
                <TableCell>
                  {dateFormat(selectedInventoryWastages.updatedAt)}
                </TableCell>
                <TableCell>
                  {selectedInventoryWastages.AdjustmentInventory.length}
                </TableCell>
                <TableCell>
                  {selectedInventoryWastages.User.firstName +
                    selectedInventoryWastages.User.lastName}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Grid item xs={12}>
          <Typography variant="h4" className={classes.heading}>
            Products Details
          </Typography>
        </Grid>
        <TableContainer className={classes.parentContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableBody>
              <TableRow className={classes.shadedTableHeader}>
                {productsColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      background: 'transparent',
                      fontWeight: 'bolder',
                      fontSize: '12px'
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
            <TableBody>
              {selectedInventoryWastages.AdjustmentInventory.map(
                (inventoryWastage) => {
                  return inventoryWastage.StockAdjustmentBatch.map((batch) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={inventoryWastage.id}
                      >
                        {productsColumns.map((column) => {
                          const value = inventoryWastage[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className={
                                column.className &&
                                typeof column.className === 'function'
                                  ? column.className(value)
                                  : column.className
                              }
                            >
                              {column.format
                                ? column.format(
                                    value,
                                    inventoryWastage.Inventory,
                                    batch
                                  )
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  });
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  ) : null;
}

export default ViewStockManagementDetails;
