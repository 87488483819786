import React from 'react';
import { Autocomplete as MuiAutocomplete, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from './TextField';
import { Close } from '@material-ui/icons';

const Autocomplete = ({
  options,
  value,
  onChange,
  label,
  placeholder,
  hover,
  customInputProps = {},
  sxProps = {},
  listBoxProps = {},
  error,
  size = 'small',
  disableClearable = true,
  resetValue,
  ...rest
}) => {
  const CustomPaper = (props) => {
    return (
      <Paper
        elevation={8}
        {...props}
        className="shadow-[0px 8px 12px 0px #0000001F] mt-3 border border-solid border-transparent"
      />
    );
  };

  return (
    <MuiAutocomplete
      options={options}
      value={value || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          value={value || ''}
          sxProps={sxProps}
          label={label}
          variant="outlined"
          placeholder={placeholder}
          hover={hover}
          size={size}
          className="bg-red"
          InputProps={{
            ...params.InputProps,
            ...customInputProps,
            endAdornment: (
              <React.Fragment>
                {resetValue && (
                  <Close
                    color={'inherit'}
                    style={{ fontSize: '16px', cursor: 'pointer' }}
                    onClick={resetValue}
                  />
                )}
                <ExpandMoreIcon color={'inherit'} size={20} className="mr-0" />
              </React.Fragment>
            )
          }}
          error={error}
        />
      )}
      onChange={onChange}
      className="rounded outline-none cursor-pointer text-sm"
      size={size}
      PaperComponent={CustomPaper}
      disableClearable={disableClearable}
      ListboxProps={{
        sx: {
          ...{ listBoxProps },
          '& .MuiAutocomplete-option': {
            fontSize: '14px'
          },
          '&::-webkit-scrollbar': {
            width: '4px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1'
          }
        }
      }}
      {...rest}
    />
  );
};

export default Autocomplete;
