import React, { useRef, useState } from 'react';
import deleteIcon from '../../../../assets/icons/deleteIcon.svg';
import editIcon from '../../../../assets/icons/editIconDark.svg';
import viewIcon from '../../../../assets/icons/viewIcon.svg';
import { Paragraph } from '../../../../core-components/atoms/Text';
import API from '../../../../libs/axios';
import { toaster } from '../../../../utils/toaster';
import { checkValidationErr } from '../../../../services/BulkUploadServices';
import { checkPermission } from '../../../../utils/auth';
import CategoryView from './CategoryView';

const CategoryListComponent = ({
  data,
  searchValue,
  onClick,
  getCategories,
  currentUser,
  editOpen
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [showProductList, setShowProductList] = useState(false);
  const anchorRef = useRef(null);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    if (!showProductList && !editOpen) {
      setIsHovering(false);
    } else {
      setIsHovering(true);
    }
  };

  const removeCategory = async () => {
    try {
      await API.delete(`categories/${data.id}`);
      toaster('success', `Category deleted successfully.`);

      getCategories();
    } catch (error) {
      checkValidationErr(
        error,
        `unique_category_id`,
        `$ already exists it must be unique`
      );
    }
  };

  return (
    <>
      <CategoryView
        anchorRef={anchorRef}
        setOpen={setShowProductList}
        open={showProductList}
        categoryDetail={data}
        isBrand={false}
      />
      <div
        className="flex items-center justify-between bg-white cursor-pointer shadow-elevation-1 px-4 py-4 border-b"
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
      >
        <Paragraph
          variant={'sm'}
          className={`${
            searchValue && 'bg-[#FFF0C3]'
          } flex max-w-[200px] min-w-[187px] truncate`}
        >
          {data.name}
        </Paragraph>
        <Paragraph
          variant={'sm'}
          className={`${searchValue && 'bg-[#FFF0C3]'} min-w-[187px]`}
        >
          {data.productsCount}
        </Paragraph>

        <div className={'flex h-6 w-20 justify-end gap-2'}>
          {isHovering && (
            <button
              ref={anchorRef}
              onClick={() => {
                setShowProductList(true);
              }}
            >
              <img src={viewIcon} alt="view-icon" className={'h-6 w-6'} />
            </button>
          )}
          {isHovering && (
            <button onClick={onClick}>
              <img src={editIcon} alt="edit-icon" className={'h-6 w-6'} />
            </button>
          )}
          {isHovering &&
            checkPermission(currentUser, 'OPS_CATEGORY_DELETE') && (
              <button
                onClick={async (event) => {
                  event.stopPropagation();
                  await removeCategory();
                }}
              >
                <img src={deleteIcon} alt="delete-icon" className={'h-6 w-6'} />
              </button>
            )}
        </div>
      </div>
    </>
  );
};

export default CategoryListComponent;
