/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  useRoutes,
  useLocation,
  useSearchParams
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import GlobalStyles from '../src/components/GlobalStyles';
import theme from '../src/theme';
import routes from '../src/routes';
import { SharedContext } from './utils/common';
import { toaster } from './utils/toaster';
import { getUser, getUserToken, removeAuth } from './utils/auth';
import {
  setRequestInterceptor,
  setResponseInterceptor,
  ejectRequestInterceptor,
  ejectResponseInterceptor
} from './utils/interceptors';
import LazyLoader from './components/LazyLoader';
import API from './libs/axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import { endOfMonth, startOfMonth } from 'date-fns';
import useFeatureFlags from './hooks/useFeatureFlags';
import FLAGS from './constants/featureFlags';
import {
  sizes,
  volumes,
  weights
} from './views/administration/Settings/config';
import timezones from './utils/timezones.json';
import config from './config/';
import FlashyBanner from './atomicComponents/FlashyBanner';
import { LOCKABLE_TENANTS } from './constants';
import TrialExpiryBanner from './components/TrialExpiryBanner';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false // default: true
    }
  }
});

const App = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isFeatureEnabled } = useFeatureFlags();
  const [isLoading, setIsLoading] = useState(false);
  const [apiLoader, setAPILoader] = useState(false);
  const [authToken, setAuthToken] = useState(getUserToken());
  const [currentPageTitle, setCurrentPageTitle] = useState('');
  const [currentPageIcon, setCurrentPageIcon] = useState(null);
  const [sidebar, setSidebar] = useState(true);
  const [currentUser, setCurrentUser] = useState(getUser());
  const [subdomain, setSubdomain] = useState('');
  const [organization, setOrganization] = useState(null);
  const [organizationId, setOrganizationId] = useState();
  const [organizationType, setOrganizationType] = useState();
  const [lightOrganizationLogoFile, setLightOrganizationLogoFile] = useState();
  const [darkOrganizationLogoFile, setDarkOrganizationLogoFile] = useState();
  const [dashboardSelected, setDashboardSelected] = useState(1);
  const [startDateIntelligence, setStartDateIntelligence] = useState(
    startOfMonth(new Date())
  );
  const [endDateIntelligence, setEndDateIntelligence] = useState(
    endOfMonth(new Date())
  );
  const [nodeIdsIntelligence, setNodeIdsIntelligence] = useState([]);
  const [settings, setSettings] = useState({});

  const organizationSetupDomain = config.REACT_APP_CLIENT_DOMAIN.replace(
    '<subdomain>',
    'account'
  );
  let token = searchParams.get('verificationToken');

  const routing = useRoutes(
    routes(
      currentUser,
      organization?.organization?.type,
      isFeatureEnabled,
      window.location.origin === organizationSetupDomain,
      token
    )
  );

  const [selectedMapLocation, setSelectedMapLocation] = useState(null);
  const [formErrors, setFormErrors] = useState('');

  const checkOrganization = async () => {
    try {
      setAPILoader(true);
      const organization = await API.get('check');
      if (!organization.organization) {
        navigate('/404');
      } else {
        if (organization.organization.selfCreated) {
          const subscription = await API.get(
            `organizations/${organization.organization.subdomain}/subscription/validate`
          );

          if (!subscription.valid) {
            toaster(
              'warning',
              'Your subscription is expired. Kindly renew your plan!'
            );
            navigate('/subscription-plans');
          }
        }

        setSubdomain(organization.organization.subdomain);
        setOrganizationId(organization.organization.id);
        setOrganizationType(organization.organization.type);
        setOrganization(organization.organization);
        setLightOrganizationLogoFile(organization.lightLogoFile);
        setDarkOrganizationLogoFile(organization.darkLogoFile);
        setSettings({
          weight: weights.find(
            (w) => w.value == organization?.organization?.GeneralSetting?.weight
          ),
          size: sizes.find(
            (s) => s.value == organization?.organization?.GeneralSetting?.size
          ),
          volume: volumes.find(
            (v) => v.value == organization?.organization?.GeneralSetting?.volume
          ),
          dateFormat:
            organization?.organization?.GeneralSetting?.dateFormat ||
            'DD/MM/YYYY',
          timezone:
            timezones?.find(
              (tz) =>
                tz.tzCode ==
                organization?.organization?.GeneralSetting?.timeZone
            ) || timezones?.find((tz) => tz.tzCode == 'Asia/Karachi')
        });
      }
      setAPILoader(false);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const updateInterceptors = () => {
    const requestInterceptor = setRequestInterceptor(() => {
      setIsLoading(true);
    });
    const responseInterceptor = setResponseInterceptor(
      (data) => {
        setIsLoading(false);
        return data;
      },
      (error) => {
        setIsLoading(false);
        if (error.response && error.response.status == 401) {
          if (location.pathname.split('/').pop() != 'login') {
            setCurrentUser(null);
            removeAuth();
            navigate('/login');
          }
        }
        return Promise.reject(error);
      }
    );
    return { requestInterceptor, responseInterceptor };
  };
  updateInterceptors();
  useEffect(async () => {
    const { requestInterceptor, responseInterceptor } =
      await updateInterceptors();
    return async () => {
      await ejectRequestInterceptor(requestInterceptor || 0);
      await ejectResponseInterceptor(responseInterceptor || 0);
    };
  }, [authToken]);

  useEffect(async () => {
    if (window.location.origin === organizationSetupDomain) return;

    checkOrganization();

    if (isFeatureEnabled(FLAGS.O_FRIEND)) return;

    const style = document.createElement('style');
    style.textContent = `
      .help-button {
        display: none !important;
      }

      #jsd-widget {
        display: none !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    const urlPath = location.pathname;
    if (currentUser) {
      if (
        (urlPath.includes('driver') || urlPath.includes('vehicle')) &&
        isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
      ) {
        navigate('/administration/access');
      }
    }
  }, [currentUser, organization]);

  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <SharedContext.Provider
          value={{
            isLoading,
            setIsLoading,
            formErrors,
            setFormErrors,
            authToken,
            currentUser,
            setAuthToken,
            setCurrentUser,
            currentPageTitle,
            setCurrentPageTitle,
            currentPageIcon,
            setCurrentPageIcon,
            sidebar,
            setSidebar,
            selectedMapLocation,
            setSelectedMapLocation,
            apiLoader,
            setAPILoader,
            subdomain,
            organizationId,
            organization,
            setOrganization,
            organizationType,
            setOrganizationType,
            fetchOrganization: checkOrganization,
            lightOrganizationLogoFile,
            darkOrganizationLogoFile,
            dashboardSelected,
            setDashboardSelected,
            startDateIntelligence,
            setStartDateIntelligence,
            endDateIntelligence,
            setEndDateIntelligence,
            nodeIdsIntelligence,
            setNodeIdsIntelligence,
            settings
          }}
        >
          <QueryClientProvider client={queryClient}>
            <FlashyBanner
              tenants={LOCKABLE_TENANTS}
              currentSubdomain={subdomain}
            />
            <TrialExpiryBanner />
            <LazyLoader />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange={false}
              draggable
              pauseOnHover
            />
            <GlobalStyles />
            {!subdomain && apiLoader ? <></> : routing}
          </QueryClientProvider>
        </SharedContext.Provider>
      </DndProvider>
    </ThemeProvider>
  );
};

export default App;
