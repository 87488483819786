/*eslint-disable*/
import { useContext, useEffect, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import API from '../../../libs/axios';
import Popup from '../../../core-components/atoms/Popup';
import Button from '../../../core-components/atoms/Button';
import TextField from '../../../core-components/atoms/TextField';
import { ArrowDropDown, Close, Search } from '@material-ui/icons';
import CustomTextField from '../../../core-components/atoms/TextField';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import {
  dateFormatSlashDivider,
  decimalDisplay,
  inventoryLocationFormat,
  productLabelFormat,
  SharedContext
} from '../../../utils/common';
import { toaster } from '../../../utils/toaster';
import { NumericFormat } from 'react-number-format';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';
import BatchesFilter from '../components/BatchesFilter';
import LocationsFilter from '../components/LocationsFilter';
import CustomPagination from '../../invoiceandbilling/invoiceManagement/CustomPagination';

const ReleaseInventory = ({
  inventoryIds,
  batchIds,
  refetch,
  stock360Enabled,
  batchExpiryEnabled,
  open,
  setOpen,
  warehouseIds,
  callBack
}) => {
  const [batches, setBatches] = useState([]);
  const [search, setSearch] = useState('');
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [persistedData, setPersistedData] = useState({});
  const [locationFilterOpen, setLocationFilterOpen] = useState(false);
  const [batchesFilterOpen, setBatchesFilterOpen] = useState(false);
  const [batchFilterIds, setBatchFilterIds] = useState({});
  const [locationFilterIds, setLocationFilterIds] = useState({});

  const { setAPILoader } = useContext(SharedContext);
  const { isFeatureEnabled } = useFeatureFlags();

  const fetchBatches = async () => {
    setAPILoader(true);
    const res = await API.get('inventory-details', {
      params: {
        inventoryIds,
        batchIds,
        search,
        batchNumbers: [
          ...Object.keys(batchFilterIds).map((b) => batchFilterIds[b])
        ]
          .filter((b) => b.length > 0)
          .join(' '),
        locations: [
          ...Object.keys(locationFilterIds)
        ]
          .filter((b) => b.length > 0)
          .join(','),
        limit: 10,
        page,
        inventoryOperation: "RELEASE"
      }
    });
    let parsedBatches = [];
    setPages(res.pages)
    if (res?.data?.length > 0) {
      for (let i = 0; i < res.data.length; i++) {
        const row = res.data[i];
        if (row.holdQuantity > 0) {
          parsedBatches.push({
            holdQuantity: row.holdQuantity,
            quantity: persistedData[row?.id]?.holdQuantity || 0,
            inventoryId: row.inventoryId,
            product: row?.Inventory?.Product,
            id: row?.id,
            batchNumber: row?.batchNumber || row?.batchName,
            expiryDate: row?.expiryDate,
            mfgDate: row?.manufacturingDate,
            binLocation: row?.WarehouseHall,
            uom: {
              ...row?.Inventory?.Product?.UOM,
              primary: true,
              conversionFactor: 1
            },
            warehouse: row?.Inventory?.Warehouse
          });
        }
      }
    }

    setBatches(parsedBatches);
    setOpen(true);
    setAPILoader(false);
  };

  const handleInputChange = (id, field, value, metaValues) => {
    setPersistedData((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        ...metaValues,
        [field]: value
      }
    }));
  };

  const applyRelease = async () => {
    setAPILoader(true);
    try {
      const payload = [];
      for (let key of Object.keys(persistedData)) {
        if (persistedData[key].quantity == 0 || !persistedData[key].quantity) {
          continue;
        }

        payload.push({
          quantity: persistedData[key].uom?.primary
            ? Number(persistedData[key].quantity)
            : Number(
                (
                  persistedData[key].quantity *
                  persistedData[key].uom?.conversionFactor
                ).toFixed(3)
              ),
          inventoryDetailId: persistedData[key].inventoryDetailId,
          inventoryId: persistedData[key].inventoryId
        });
      }

      await API.put(`inventories/hold/remove`, payload);
      await refetch();
      toaster('success', 'Inventory Released Successfully');
      setOpen(false);
      setBatches([]);
      callBack && callBack();
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    if ((open && inventoryIds?.length > 0) || (open && batchIds?.length > 0)) {
      fetchBatches();
    }
  }, [open, inventoryIds, search, batchFilterIds, locationFilterIds, page]);

  const releaseAll = async () => {
    setAPILoader(true);
    let parsedBatches = {};
    try {
      const res = await API.get('inventory-details', {
        params: { inventoryIds, batchIds, fetchAll: true }
      });
      if (res?.data?.length > 0) {
        for (let i = 0; i < res.data.length; i++) {
          const row = res.data[i];
          if (row.holdQuantity > 0) {
            parsedBatches[row?.id] = {
              quantity:
                row.holdQuantity /
                (persistedData?.[row?.id]?.uom?.conversionFactor || 1),
              inventoryId: row.inventoryId,
              inventoryDetailId: row?.id,
              uom:
                persistedData?.[row?.id]?.uom ||
                batches?.find((b) => b.id == row?.id)?.uom
            };
          }
        }
      }
      setPersistedData(parsedBatches);
    } catch (e) {
      console.error(e);
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <>
      <Popup
        open={open}
        setOpen={setOpen}
        title={<>Release Inventory</>}
        actions={
          <div className="flex gap-4 mr-4">
            <Button
              label={`Cancel`}
              variant="tranparent"
              labelClass="font-medium text-xs"
              onClick={async (e) => {
                setBatches([]);
                setOpen(false);
              }}
            />
            <Button
              label={`Release Items`}
              variant="primary"
              labelClass="font-medium text-xs"
              onClick={async (e) => {
                await applyRelease();
              }}
            />
          </div>
        }
        content={
          <div className="p-4">
            <div className="flex gap-4 items-center justify-between">
              <p className="text-[18px] font-bold">
                {inventoryIds?.length}{' '}
                {inventoryIds?.length > 1 ? 'Products' : 'Product'} selected
              </p>
              <div className="flex gap-4">
                <CustomTextField
                  placeholder="Search Table"
                  variant="outlined"
                  color={'primary'}
                  size="small"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  inputProps={{
                    onFocus: () => search
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <Search
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '10px'
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {search && (
                          <Close
                            style={{
                              width: '20px',
                              height: '20px',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              setSearch('');
                            }}
                          />
                        )}
                      </InputAdornment>
                    )
                  }}
                />
                {isFeatureEnabled(FLAGS.LOCATION_HIERARCHY) && (
                  <LocationsFilter
                    open={locationFilterOpen}
                    setOpen={setLocationFilterOpen}
                    warehouseIds={warehouseIds}
                    values={locationFilterIds}
                    setValues={setLocationFilterIds}
                    callBack={() => setPage(1)}
                  />
                )}
                {isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING) && (
                  <BatchesFilter
                    open={batchesFilterOpen}
                    setOpen={setBatchesFilterOpen}
                    batchIds={batchIds}
                    values={batchFilterIds}
                    setValues={setBatchFilterIds}
                    inventoryIds={inventoryIds}
                    callBack={() => setPage(1)}
                  />
                )}
                <Button
                  label="Release All Items"
                  variant="primary"
                  onClick={async () => await releaseAll()}
                />
              </div>
            </div>
            <TableContainer
              component={Paper}
              className="mt-10 w-full"
              sx={{
                borderRadius: '16px',
                border: '1px solid #E7E6E6 !important',
                outline: 'none !important'
              }}
              elevation={0}
            >
              <Table sx={{ border: 'none' }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ width: '' }}
                      className="font-normal text-[#696F80] text-sm whitespace-nowrap"
                    >
                      SKU Name & Node
                    </TableCell>
                    {stock360Enabled && (
                      <TableCell
                        style={{ width: '' }}
                        className="font-normal text-[#696F80] text-sm whitespace-nowrap"
                      >
                        Bin Location
                      </TableCell>
                    )}
                    {batchExpiryEnabled && (
                      <TableCell
                        style={{ width: '' }}
                        className="font-normal text-[#696F80] text-sm whitespace-nowrap"
                      >
                        Batch No, MFG & EXP
                      </TableCell>
                    )}
                    <TableCell
                      className="font-normal text-[#696F80] text-sm whitespace-nowrap"
                      style={{ width: '' }}
                    >
                      On-Hold Qty
                    </TableCell>
                    <TableCell
                      className="font-normal text-[#696F80] text-sm whitespace-nowrap"
                      style={{ width: '' }}
                    >
                      UoM
                    </TableCell>
                    <TableCell
                      className="font-normal text-[#696F80] text-sm whitespace-nowrap"
                      style={{ width: '' }}
                    >
                      Release Qty
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batches?.map((batch, id) => {
                    const uoms = [
                      {
                        ...batch.product?.UOM,
                        primary: true,
                        conversionFactor: 1
                      },
                      ...(batch.product?.ProductUOMs?.map((uom) => ({
                        conversionFactor: uom.conversionFactor,
                        name: uom?.UOM?.name,
                        primary: false
                      })) || [])
                    ];

                    let metaPayload = {
                      inventoryDetailId: batch.id,
                      inventoryId: batch.inventoryId,
                      uom: persistedData[batch?.id]?.uom || batch.uom
                    };

                    return (
                      <TableRow>
                        <TableCell style={{ width: '15%' }}>
                          <p className="text-sm whitespace-nowrap">
                            {productLabelFormat(batch?.product).length > 20
                              ? `${productLabelFormat(batch?.product).substring(
                                  0,
                                  20
                                )}...`
                              : `${productLabelFormat(batch?.product)}`}
                          </p>
                          <p className="text-xs whitespace-nowrap overflow-ellipsis mt-2">
                            {inventoryLocationFormat(batch?.warehouse).length >
                            20
                              ? `${inventoryLocationFormat(
                                  batch?.warehouse
                                ).substring(0, 20)}...`
                              : `${inventoryLocationFormat(batch?.warehouse)}`}
                          </p>
                        </TableCell>
                        {stock360Enabled && (
                          <TableCell
                            style={{ width: '' }}
                            className="text-xs whitespace-nowrap"
                          >
                            <p className="text-sm">{batch.binLocation?.name}</p>
                            <div className="flex gap-2 mt-2 whitespace-nowrap">
                              <span>
                                Aisle: {batch.binLocation?.Aisle?.name}
                              </span>
                              <span>Bay: {batch.binLocation?.Bay?.name}</span>
                            </div>
                          </TableCell>
                        )}
                        {batchExpiryEnabled && (
                          <TableCell
                            style={{ width: '' }}
                            className="text-xs whitespace-nowrap"
                          >
                            {batch.product.batchEnabled ? (
                              <>
                                <p className="text-sm">
                                  {batch.batchNumber?.length > 15
                                    ? `${batch.batchNumber?.substring(
                                        0,
                                        15
                                      )}...`
                                    : batch.batchNumber}
                                </p>
                                <p className="mt-2">
                                  {dateFormatSlashDivider(batch.expiryDate)} -{' '}
                                  {dateFormatSlashDivider(batch.mfgDate)}
                                </p>
                              </>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        )}
                        <TableCell
                          className="text-sm whitespace-nowrap"
                          style={{ width: '5%' }}
                        >
                          {decimalDisplay(
                            batch?.holdQuantity /
                              (persistedData[batch.id]?.uom?.conversionFactor ||
                                1)
                          )}
                        </TableCell>
                        <TableCell style={{ width: '25%' }}>
                          <Autocomplete
                            value={persistedData[batch.id]?.uom || uoms[0]}
                            onChange={(e, value) => {
                              const convertedValue =
                                (persistedData?.[batch.id]?.quantity *
                                  (persistedData?.[batch.id]?.uom
                                    ?.conversionFactor || 1)) /
                                value.conversionFactor;
                              handleInputChange(batch?.id, 'uom', value, {
                                ...metaPayload,
                                quantity: convertedValue
                              });
                            }}
                            options={uoms}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                placeholder="UOM"
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    fontSize: '12px'
                                  },
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      {...params.endAdornment}
                                    >
                                      <IconButton>
                                        <ArrowDropDown />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            )}
                            getOptionLabel={(uom) => uom?.name || ''}
                            renderOption={(props, value) => (
                              <li className="auto-complete-option" {...props}>
                                {value.name} {value.primary ? ' (primary)' : ''}
                              </li>
                            )}
                          />
                        </TableCell>
                        <TableCell
                          className="text-xs whitespace-nowrap"
                          style={{ width: '15%' }}
                        >
                          <NumericFormat
                            customInput={CustomTextField}
                            placeholder="qty"
                            variant="outlined"
                            size="small"
                            decimalScale={3}
                            fullWidth
                            value={persistedData?.[batch.id]?.quantity || 0}
                            onValueChange={(e) => {
                              handleInputChange(
                                batch?.id,
                                'quantity',
                                e.value,
                                metaPayload
                              );
                            }}
                            isAllowed={(values) => {
                              const { floatValue } = values;
                              const uomConvertedValue = batch.uom?.primary
                                ? batch.holdQuantity
                                : Number(
                                    (
                                      batch.holdQuantity /
                                      batch.uom?.conversionFactor
                                    ).toFixed(3)
                                  );
                              if (uomConvertedValue - floatValue < 0) {
                                return false;
                              }
                              return true;
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomPagination pages={pages} page={page} setPage={setPage} />
          </div>
        }
      />
      <Button
        variant="transparent"
        label={`Release Items`}
        className="py-2 px-3 ml-2 h-8 border-solid border "
        overrideSize={true}
        labelClass="font-medium text-xs"
        onClick={(e) => {
          setOpen(true);
        }}
      />
    </>
  );
};

export default ReleaseInventory;
